.banners {
  height: 470px;
  position: relative;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;

  &--400 {
    height: 400px;
  }
  &--500 {
    height: 500px;
  }
}
.banners__content {
  position: relative;
  background: transparent;
  opacity: 1;
  left: 700px;
  top: 104px;

  &--left {
    position: relative;
    width: 33%;
    float: left;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
    padding: 0 30px 0 50px;
  }

  &--center {
    left: 15%;
    top: 20%;
    width: 500px;
  }
  &--right {
    top: 40%;
    width: 300px;
    text-align: center;
  }

  &--about {
    width: 420px;
  }
}
.banners__wide{
  float: left;
  width: 66%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.banners__title {
  font-family: "PFRegalDisplayPro-Italic", sans-serif;
  color: #000000;
  font-size: 25px;
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 22px;

  &--top {
    color: #ffffff;
    font-size: 23px;
    margin-top: 12px;
    text-align: center
  }

  &--grey {
    color: #d1ced0;
  }
  &--white {
    color: #fff;
  }
}

.banners__desc {
  &--justify {
    text-align: justify;
  }
  &--grey {
    color: #d1ced0;
  }
}
.banners__btn {
  margin-top: 55px;
  &--center {
    text-align: center;
  }
}
.banners__btn-top {
  color: #ffffff;
  background: #000;
  border: 1px solid #fff;
}




.subscribe {
  &__agreement {
    clear: both;
    width: 550px;
    background: #fff;
    opacity: 0.6;
    color: #000;
    text-align: left;
    padding-left: 10px;
    & a {
      color: #000;
//      font-weight: bold;
      text-decoration: underline;
    }
  }
}